<script setup>
import { useRouter } from 'vue-router';
import MenuItemComponent from './MenuItemComponent.vue';
import { computed, reactive, inject } from 'vue';
import { useStore } from 'vuex';

const auth = inject('auth')

const router = useRouter()
const store = useStore()

const menus = reactive([
    {
        text: 'DASHBOARD',
        icon: 'IconDashboard',
        goTo: '/dashboard',
        exact: true,
        step: 'dashboard',
        roles: ['admin', 'manager', 'partner-finance']
    },
    {
        text: 'CALENDAR & BOOKINGS',
        icon: 'IconCalendarMenu',
        goTo: '/dashboard/calendar-and-bookings',
        step: 'calendar-bookings',
        roles: ['admin', 'manager', 'employee'],
    },
    {
        text: 'EXPERIENCES',
        icon: 'IconServicesMenu',
        goTo: '/dashboard/services',
        availableForCompanyType: 'EXPERIENCES',
        step: 'services',
        roles: ['admin', 'manager', 'employee'],
    },
    {
        text: 'TRANSFERS',
        icon: 'IconServicesMenu',
        goTo: '/dashboard/vehicles/preview',
        availableForCompanyType: 'TRANSFERS',
        step: 'transfers',
        roles: ['admin', 'manager', 'employee'],
    },
    {
        text: 'SALES',
        icon: 'IconSalesMenu',
        goTo: '/dashboard/sales',
        step: 'sales',
        roles: ['admin', 'manager', 'partner-finance'],
    },
    {
        text: 'AFFILIATE AREA',
        icon: 'IconPromoterArea',
        goTo: '/dashboard/promoter-area',
        step: 'affiliate-area',
        roles: ['admin'],
    },
])

const affiliateMenus = reactive([
    {
        text: 'DASHBOARD',
        icon: 'IconDashboard',
        goTo: '/affiliates/dashboard',
        exact: true,
    },
    {
        text: 'AFFILIATE AREA',
        icon: 'IconPromoterArea',
        goTo: '/affiliates'
    },
    {
        text: 'DASHBOARD CLIENT',
        icon: 'IconDashboard',
        goTo: '/user-panel/bookings'
    },
])

const currentMenu = computed(() => {
    if (auth.value.user_type === 'AFFILIATE') {
        return affiliateMenus
    }

    return menus
})

function canShow(menuItem) {
    if (menuItem.roles && ! menuItem.roles.includes(auth.value?.role?.role_slug)) {
        return false;
    }

    if (menuItem.availableForCompanyType) {
        const modules = JSON.parse(auth.value?.company?.modules)

        if (menuItem.availableForCompanyType === 'EXPERIENCES') {
            return modules.experiences == 1;
        }
    
        if (menuItem.availableForCompanyType === 'TRANSFERS') {
            return modules.transfers == 1;
        }
    }

    return true;
}
</script>

<template>
    <div class="menu-container">
        <template v-for="(menuItem, index) of currentMenu" :key="index">

            <router-link v-if="canShow(menuItem)" :to="menuItem.goTo" custom v-slot="{isExactActive, isActive, navigate}" >
                <MenuItemComponent :text="menuItem.text" :icon="menuItem.icon" :active="menuItem.exact ? isExactActive : isActive" @click="navigate" :id="menuItem.step"/>
            </router-link>

        </template>
    </div>
</template>

<style lang="scss" scoped>
.menu-container {
    ::v-deep {
        button {
            margin-bottom: 13.5px;
        }
    }

    &.toggled {
        ::v-deep {
            button {
                padding: 8px;
                margin-top: 13.5px;
                margin-bottom: 13.5px;

                span {
                    display: none;
                }
            }

            .menu-arrow {
                display: none;
            }
        }
    }
}
</style>